import React, { Component, Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import ProtectedRoute from './components/ProtectedRoute'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
const ResetPassword = React.lazy(() => import('./views/pages/pwreset/ResetPassword'))
const RegisterVerificationEmail = React.lazy(() =>
  import('./views/pages/register/RegisterVerificationEmail'),
)

const SESSION_STORAGE_USER = 'clubster_webui_session'

export const ClubContext = React.createContext()

class App extends Component {
  constructor(props) {
    super(props)
    let sessionRestore = window.sessionStorage.getItem(SESSION_STORAGE_USER)
    this.state = {
      sessionUser: sessionRestore != null ? JSON.parse(sessionRestore) : null,
    }
  }

  handleLogin = (user) => {
    this.setState({ sessionUser: user })
    window.sessionStorage.setItem(SESSION_STORAGE_USER, JSON.stringify(user))
  }

  handleLogout = () => {
    this.setState({ sessionUser: null })
    window.sessionStorage.setItem(SESSION_STORAGE_USER, null)
  }

  render() {
    return (
      <ClubContext.Provider value={{ currentClub: null, breadcrumb: null }}>
        <BrowserRouter>
          <Suspense fallback={loading}>
            <Routes>
              <Route
                exact
                path="/login"
                name="Anmeldung"
                element={<Login handleLogin={this.handleLogin} handleLogout={this.handleLogout} />}
              />
              <Route
                exact
                path="/logout"
                name="Sie werden abgemeldet..."
                element={<Login handleLogin={this.handleLogin} handleLogout={this.handleLogout} />}
              />
              <Route exact path="/register" name="Registrierung" element={<Register />} />
              <Route exact path="/404" name="Inhalt nicht gefunden!" element={<Page404 />} />
              <Route exact path="/500" name="Zugang verweigert!" element={<Page500 />} />
              <Route
                path="/reset-password/:id"
                name="Passwort zurücksetzen"
                element={<ResetPassword />}
              />
              <Route
                path="/register/verify/email/:id"
                name="Email-Bestätigung"
                element={<RegisterVerificationEmail />}
              />
              <Route
                path="*"
                name="Home"
                element={
                  <ProtectedRoute user={this.state.sessionUser}>
                    <DefaultLayout
                      user={this.state.sessionUser}
                      handleLogin={this.handleLogin}
                      handleLogout={this.handleLogout}
                    />
                  </ProtectedRoute>
                }
              />
            </Routes>
          </Suspense>
        </BrowserRouter>
      </ClubContext.Provider>
    )
  }
}

export default App
