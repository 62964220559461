import React, { Component } from 'react'
import { Navigate } from 'react-router-dom'

/**
 * Handles that certain sites only can get accessed as authorized user.
 */
export default class ProtectedRoute extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    if (!this.props.user) return <Navigate to={'/login'} />
    else return this.props.children
  }
}
