/**
 * This file is responsible for all i18n Strings in the project.
 * For more information see https://docs.expo.io/versions/latest/sdk/localization/
 *
 * to call the string for the current language, use i18n.t('value')
 *
 * @author Sebastian Göhring
 */
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-xhr-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
// Set the locale once at the beginning of your app.
const locale = 'de'

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    backend: {
      /* translation file path */
      // loadPath: './translations/{{lng}}/{{ns}}.json'
      loadPath: '/translations/{{lng}}/common.json',
    },
    lng: locale,
    supportedLngs: ['de', 'en'],
    fallbackLng: 'de',
  })

export default i18n
